<svelte:head>
	<title>For Areena ♥</title>
	<meta name="description" content="A personal minisite, by Sreetam" />
</svelte:head>

<section>
	<h1 class="heading-big">This is <br />a teapot.</h1>
</section>

<style lang="postcss">
	section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
		padding-top: 120px;
	}

	.heading-big > br {
		display: none;
	}

	@media (max-width: 400px) {
		.heading-big > br {
			display: block;
		}
	}
</style>
